<template>
    <b-card no-body>
      <b-card-header>
        <!-- title and subtitle -->
        <div>
          <!-- <b-card-sub-title class="mb-25">
            Balance
          </b-card-sub-title> -->
          <b-card-title class="font-weight-bolder">
            Employees Mood this week
          </b-card-title>
        </div>
        <!--/ title and subtitle -->
  
        <!-- datepicker -->
        <div class="d-flex align-items-center">
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <flat-pickr
            v-model="rangePicker"
            :config="{ mode: 'range'}"
            class="form-control flat-picker bg-transparent border-0 shadow-none"
            placeholder="YYYY-MM-DD"
          />
        </div>
        <!-- datepicker -->
      </b-card-header>
  
      <b-card-body>
        <vue-apex-charts
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
        />
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle,
  } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import flatPickr from 'vue-flatpickr-component'
  import apexChatData from './apexChartData'
  
import { $themeColors } from '@themeConfig'
const $barColor = '#f3f3f3'
  export default {
    components: {
      VueApexCharts,
      BCard,
      BCardHeader,
      flatPickr,
      BCardBody,
      BCardSubTitle,
      BCardTitle,
    },
    props: {
        moods: {
        type: Object,
        default: () => {},
        },
    },
    data() {
      return {
        series: [
        {
            data: [50, 50, 50, 50, 50],
        },
        ],
        rangePicker: ['2022-10-30', '2022-11-05'],
        chartOptions:{
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -15,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: '20%',
              startingShape: 'rounded',
              colors: {
                backgroundBarColors: [$barColor, $barColor, $barColor, $barColor, $barColor],
                backgroundBarRadius: 5,
              },
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: true,
          },
          colors: ['#38B22D'],

          xaxis: {
            labels: {
              show: false,
            },
            categories: ['Frustrated', 'Stressed', 'Neutral', 'Focused', 'Energetic'],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: true,
            },
          tooltip: {
            x: {
              show: false,
            },
            y: {
              show: false,
            },
          },
        },
      }
    },
    created(){
        if(this.moods){
            this.rangePicker = this.moods.range;
            this.series =  [
                {
                    data: [
                        this.moods.data.Frustrated,
                        this.moods.data.Stressed,
                        this.moods.data.Neutral,
                        this.moods.data.Focused,
                        this.moods.data.Energetic,
                    ],
                },
            ];
        }
    }
  }
  </script>