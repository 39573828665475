<template>
  <b-card
    v-if="false"
    no-body
  >
    <b-card-header>
      <b-card-title class="ml-25">
        Recent FeedBack Timeline
      </b-card-title>
    </b-card-header>

    <!-- timeline -->
    <b-card-body>
      <app-timeline>
        <app-timeline-item
          v-for="(item, index) in data"
          :id="index"
          :key="index"
          :title="item.interview_title"
          :subtitle="item.msg"
          icon="InfoIcon"
          :time="item.time"
          :item="item"
          variant="info"
        />
      </app-timeline>
      <!--/ timeline -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, VBTooltip,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
